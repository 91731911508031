import React from "react";
import { Form } from "react-bootstrap";
import Calendar from "react-calendar";
import Timeslots from "./Timeslots";
import { Container } from "@mantine/core";
import { Grid, Col } from "@mantine/core";
import { useState } from "react";

const changeFunction = () => {
  console.log("HI");
};

const CalenderSlotObt = () => {
  const [value] = useState(new Date());

  const dateFlag = (date) => {
    return date.getDay() === 0 || date.getDay() === 6 || date === new Date();
  };

  return (
    <Form.Group className="mb-3" controlId="formBasicText">
      <Container className="scheduler-container">
        <Grid>
          <Col span={9}>
            <Calendar
              onChange={changeFunction}
              value={value}
              tileDisabled={({ date }) => dateFlag(date)}
            />
          </Col>
          <Col span={3}>
            <Timeslots />
          </Col>
        </Grid>
      </Container>
    </Form.Group>
  );
};
export default CalenderSlotObt;
