import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import axios from "axios";
import CalenderSlotObt from "./ObtComponents/CalenderSlotObt";
import HeaderObt from "./Layout/HeaderObt";
import SelectObt from "./ObtComponents/SelectObt";
import Components from "./Components";
import "react-calendar/dist/Calendar.css";

import FormActionsObt from "./FormActionsObt";

const ScheduleForm = (props) => {
  return (
    <Form className="calender-form">
      <HeaderObt />
      <CalenderSlotObt />
      <FormActionsObt />
    </Form>
  );
};
export default ScheduleForm;
