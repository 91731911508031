import React from "react";
import { Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";

const FormActionsObt = () => {
  return (
    <Form.Group controlId="datetime">
      <Button variant="secondary" type="submit">
        Cancel
      </Button>
      <Button variant="primary" type="submit" className="float-end">
        Schedule Now
      </Button>
    </Form.Group>
  );
};

export default FormActionsObt;
