import React from "react";
import { Form } from "react-bootstrap";

const HeaderObt = () => {
  return (
    <Form.Group controlId="datetime">
      <h2>
        <i>Schedule Appointment</i>
      </h2>{" "}
      <br />
      <br />
      <br />
    </Form.Group>
  );
};

export default HeaderObt;
