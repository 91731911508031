import React from "react";
import Timeslot from "./Timeslot";
const Timeslots = () => {
  return (
    <div className="float-right time-slots overflow-auto">
      <Timeslot />
    </div>
  );
};
export default Timeslots;
