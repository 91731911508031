import React from "react";
import InputObt from "./ObtComponents/InputObt";
import PhoneNumberObt from "./ObtComponents/PhoneNumberObt";
import EmailObt from "./ObtComponents/EmailObt";
import MultiSelectObt from "./ObtComponents/MultiSelectObt";
import DateTimeObt from "./ObtComponents/DateTimeObt";
import SelectObt from "./ObtComponents/SelectObt";

const Components = {
  InputObt: InputObt,
  PhoneNumberObt: PhoneNumberObt,
  EmailObt: EmailObt,
  MultiSelectObt: MultiSelectObt,
  DateTimeObt: DateTimeObt,
  SelectObt: SelectObt,
};

export default (block) => {
  if (typeof Components[block.component] !== "undefined") {
    return React.createElement(Components[block.component], {
      ...block,
      key: block.name,
    });
  }
  return React.createElement(
    () => <div>The component {block.component} has not been created yet.</div>,
    { key: block.name }
  );
};
