import React from "react";
import { Form } from "react-bootstrap";

const DateTimeObt = () => {
  return (
    <Form.Group controlId="datetime">
      <Form.Label>Date and Time</Form.Label>
      <Form.Control type="datetime-local" />
    </Form.Group>
  );
};

export default DateTimeObt;
