import React from "react";
import Form from "react-bootstrap/Form";
import axios from "axios";
import { useEffect, useState } from "react";

const SelectObt = (props) => {
  return (
    <Form.Group>
      <Form.Label>{props.nameValue}</Form.Label>
      <Form.Select aria-label="Default select example"></Form.Select>
    </Form.Group>
  );
};

export default SelectObt;
