import React from "react";
const Timeslot = () => {
  return (
    <div>
      <button
        className="react-calendar__tile react-calendar__month-view__days__day timeslot"
        disabled
      >
        HH:MM
      </button>
      <button className="react-calendar__tile react-calendar__month-view__days__day timeslot">
        HH:MM
      </button>
      <button className="react-calendar__tile react-calendar__month-view__days__day timeslot">
        HH:MM
      </button>
      <button className="react-calendar__tile react-calendar__month-view__days__day timeslot">
        HH:MM
      </button>
      <button className="react-calendar__tile react-calendar__month-view__days__day timeslot">
        HH:MM
      </button>
      <button className="react-calendar__tile react-calendar__month-view__days__day timeslot">
        HH:MM
      </button>
      <button className="react-calendar__tile react-calendar__month-view__days__day timeslot">
        HH:MM
      </button>
      <button className="react-calendar__tile react-calendar__month-view__days__day timeslot">
        HH:MM
      </button>
      <button className="react-calendar__tile react-calendar__month-view__days__day timeslot">
        HH:MM
      </button>
      <button className="react-calendar__tile react-calendar__month-view__days__day timeslot">
        HH:MM
      </button>
      <button className="react-calendar__tile react-calendar__month-view__days__day timeslot">
        HH:MM
      </button>
      <button className="react-calendar__tile react-calendar__month-view__days__day timeslot">
        HH:MM
      </button>
    </div>
  );
};
export default Timeslot;
