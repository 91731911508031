import React from "react";
import { Form } from "react-bootstrap";

const InputObt = (props) => {
  return (
    <Form.Group>
      <Form.Label>{props.name}</Form.Label>
      <Form.Control
        type="input"
        placeholder={"Enter " + props.name}
        defaultValue={props.value}
        disabled={props.disabled}
      />
    </Form.Group>
  );
};

export default InputObt;
